import { Avatar, Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import useDataNotifications from '../../hooks/useDataNotifications';
import { DocumentEnum } from '../../types/documentEnum';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';

interface CardProps {
    item: INotification
}

const Card: FC<CardProps> = ({ item }) => {
    const {readNotification} = useDataNotifications()
    const navigate = useNavigate()

    const handleCLick = () => {
        readNotification(item.id)
        if(item.documentNumber) {
            if(item.documentType == DocumentEnum.SERVICES){
                navigate(`${URLS.SERVICE_DOCUMENT_PAGE.LINK}/${item.documentNumber}`)
                return
            } else if(item.documentType == DocumentEnum.ORDERS){
                navigate(`${URLS.DOCUMENTS_ITEM.LINK}/${item.documentNumber}`)
                return
            } else if(item.documentType == DocumentEnum.DELIVERY){
                return
            } else if(item.documentType == DocumentEnum.INVOICES){
                return
            } else if(item.documentType == DocumentEnum.INVOICES_MAS){
                return
            } else if(item.documentType == DocumentEnum.INVOICES_DEAL){
                return
            }
        }
    }
    return (
        <Box sx={{bgcolor:item?.read ? 'white' : '#E6F2F9', padding:'20px 0'}} onClick={() => handleCLick()}>
            <Box sx={{ display: 'flex', gap: '15px', height: '100%', alignItems: 'center' }}>
                <Box sx={{ ml:'10px',width: '20px', display: 'flex', justifyContent: 'center', height: '100%' }}>
                    {!item?.read &&
                        <Box sx={{ bgcolor: '#FF32BB', width: '6px', height: '6px', borderRadius: '50%' }} />
                    }
                </Box>
                <Box>
                    <Avatar 
                        src={item.imageLink ? item.imageLink : `${process.env.REACT_APP_MEDIA}/boxplaceholder.png`} 
                        sx={{ width: 80, height: 80 }} 
                    />
                </Box>
                <Box>
                    <Typography color='#154D72' lineHeight={'21px'} fontSize={'15px'} fontWeight={700}>
                        {item?.title}
                    </Typography>
                    <Typography color='#436F7C' fontSize={'13px'} lineHeight={'19.5px'} fontWeight={400}>
                        {item?.description}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Card;
