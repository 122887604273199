import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import useDataMachine from '../../hooks/useDataMachine';

const Info = () => {
    const {data} = useDataMachine()
    return (
        <Box>
            <Typography color='#154D72' fontWeight={700} fontSize={'20px'} lineHeight={'28px'}>
                פתיחת קריאת שירות
            </Typography>
            <Typography fontSize={'13px'} lineHeight={'19.5px'} fontWeight={400} color='#436F7C'>
                ספרו לנו מה התקלה ונציג שירות יחזור אליכם בהקדם.
            </Typography>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Box
                    sx={{
                        position: 'relative', 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: '19px',
                        width: '200px',
                        height: '200px'
                    }}
                >
                    <CardMedia
                        component="img"
                        image={data?.image ? `${process.env.REACT_APP_MEDIA_BACKEND}/media/product/${data.image.filePath}` : `${process.env.REACT_APP_MEDIA}/placeholder.png`}
                        alt="Machine image"
                        sx={{ 
                            width: '100%', 
                            height: '100%',
                            borderRadius: '15px',
                            // display: loading ? 'none' : 'block',
                            cursor: 'pointer',
                        }}
                        // onClick={() => navigate(`${URLS.MACHINE_PAGE.LINK}/${encodeURIComponent(item?.product?.serialNumber)}/${encodeURIComponent(item?.product?.sku)}`)}
                        // onLoad={handleImageLoad}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '-20px', 
                            right: '-20px',
                            zIndex: 1
                        }}
                    >
                        <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect
                                x="0.5"
                                width="52"
                                height="52"
                                rx="26"
                                fill="#102843"
                            />
                            <path
                                d="M30.1314 21.6314C29.7353 21.2354 29.5373 21.0373 29.4631 20.809C29.3979 20.6082 29.3979 20.3918 29.4631 20.191C29.5373 19.9627 29.7353 19.7646 30.1314 19.3686L32.9697 16.5303C32.2165 16.1896 31.3804 16 30.5 16C27.1863 16 24.5 18.6863 24.5 22C24.5 22.491 24.559 22.9683 24.6702 23.4251C24.7894 23.9142 24.849 24.1588 24.8384 24.3133C24.8273 24.4751 24.8032 24.5612 24.7286 24.7051C24.6574 24.8426 24.5208 24.9791 24.2478 25.2522L18 31.5C17.1716 32.3284 17.1716 33.6716 18 34.5C18.8284 35.3284 20.1716 35.3284 21 34.5L27.2478 28.2522C27.5208 27.9791 27.6574 27.8426 27.7949 27.7714C27.9388 27.6968 28.0249 27.6727 28.1867 27.6616C28.3412 27.651 28.5857 27.7106 29.0749 27.8297C29.5317 27.941 30.0089 28 30.5 28C33.8137 28 36.5 25.3137 36.5 22C36.5 21.1196 36.3104 20.2835 35.9697 19.5303L33.1314 22.3686C32.7353 22.7646 32.5373 22.9627 32.309 23.0368C32.1082 23.1021 31.8918 23.1021 31.691 23.0368C31.4626 22.9627 31.2646 22.7646 30.8686 22.3686L30.1314 21.6314Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Box>
                </Box>
            </Box>
            <Typography sx={{mt:'10px'}} color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'} textAlign={'center'}>
                {data?.title}
            </Typography>
            <Typography color='#436F7C' textAlign={'center'} fontSize={'13px'} lineHeight={'19.5px'} fontWeight={400}>
                {data?.sku}
            </Typography>
        </Box>
    );
};

export default Info;