import { Box, CardMedia, Card as MuiCard, Typography } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import { themeColors } from '../../styles';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';

interface ICard {
    item: IDocument;
    isOrder: boolean
}

const Card: FC<ICard> = ({ item, isOrder }) => {
    const images = ['boxClose.png', 'boxOpen.png'];
    const randomImage = images[Math.floor(Math.random() * images.length)];
    const navigate = useNavigate()

    return (
        <MuiCard sx={{display:'flex', gap:'20px', marginTop:'8px', padding:'10px 10px 10px 15px', position:'relative'}} onClick={() => navigate(`${isOrder ?  URLS.DOCUMENTS_ITEM.LINK : URLS.SERVICE_DOCUMENT_PAGE.LINK}/${item.documentNumber}`)}>
            <CardMedia
                component="img"
                image={item?.product?.image ? `${process.env.REACT_APP_MEDIA_BACKEND}/${item?.product?.image?.filePath}` : `${process.env.REACT_APP_MEDIA}/${randomImage}`}
                alt="Box"
                sx={{ width: '100px', height: '85px', borderRadius: '10px' }}
            />
            <Box>
                <Typography fontWeight={700} fontSize={'15px'} lineHeight={'21px'} color={themeColors.title}>
                    {isOrder ? 'הזמנה' : 'קריאת שירות'} {item.documentNumber}
                </Typography>
                <Typography fontWeight={400} fontSize={'13px'} lineHeight={'21px'} color={'#436F7C'}>
                    {moment(item.deliveryDate).format('DD.MM.YYYY')} - {item.areaDescription}
                </Typography>
            </Box>
            {isOrder ?
                <Typography sx={{position:'absolute', right:'10px', bottom:'10px'}} fontSize={'15px'} fontWeight={700} lineHeight={'21px'} color='#436F7C'>
                {item.totalQuantity} פריטים
                </Typography>
            :
                <Typography
                    sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
                    fontSize={'15px'}
                    fontWeight={700}
                    lineHeight={'21px'}
                    color="#436F7C"
                >
                    {item?.machine?.length > 20 ? `${item.machine.slice(0, 23)}...` : item.machine}
                </Typography>
            }

        </MuiCard>
    );
};

export default Card;
