import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useCatalog } from '../../store/catalog.store';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCart } from '../../store/cart.store';
import ModalWrapper from '../Modals/ModalWrapper';
import { themeColors } from '../../styles';
import ClearCart from '../Common/ClearCart';

const Options = () => {
    const {openFilter, setOpenFilter, openFavorite, setOpenFavorite} = useCatalog()
    const {cart} = useCart()
    const [openClearModal, setOpenClearModal] = useState(false)
    return (
        <>
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mt:'20px', padding:'0 10px'}}>
            <Typography fontWeight={700} fontSize={'20ox'} lineHeight={'28px'} color={themeColors.title}>
            קטלוג המוצרים
            </Typography>
            <Box>
                <IconButton onClick={() => setOpenFavorite(!openFavorite)}>
                    {openFavorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon sx={{color:themeColors.title}}/>}
                </IconButton>
  
                {!openFilter &&
                    <IconButton onClick={() => setOpenFilter(true)}>
                        <img src={`${process.env.REACT_APP_MEDIA}/filter.svg`} />
                    </IconButton>
                }
            </Box>
        </Box>
        {cart.length > 0 &&
        <Box sx={{display:'flex', gap:'5px', mb:'20px', padding:'0 10px'}} onClick={() => setOpenClearModal(!openClearModal)}>
            <Typography  color='info' sx={{textDecoration:'underline', cursor:'pointer'}}>
            איפוס הזמנה
            </Typography>
            <img src={`${process.env.REACT_APP_MEDIA}/clearCart.svg`} />
        </Box>
        }
        <ModalWrapper 
            active={openClearModal} 
            setActive={setOpenClearModal}
            component={
                <Typography color='info' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                    איפוס הזמנה
                </Typography>
            }
        >
            <ClearCart closeFunc={() => setOpenClearModal(false)}/>
        </ModalWrapper>
        </>

    );
};

export default Options;