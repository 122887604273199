import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface PolicyState {
    openPolicy: boolean,
    setOpenPolicy: (openPolicy: boolean) => void
}

export const usePolicy = create(
  persist(
    (set, get) => ({
        openPolicy: false,
        setOpenPolicy: (openPolicy) => set({openPolicy}),
    }),
    {
      name: 'policy-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<PolicyState, PolicyState>
  )
)
