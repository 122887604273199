import useSWR from 'swr';
import { AreaService } from '../services/area.service';
import { useArea } from '../store/area.store';

const fetchData = async (code: string | number): Promise<ILines> => {
  return await AreaService.getLines(code);
};

const useDataLines = () => {
  const { choosedArea } = useArea();

  const lineCode = choosedArea?.lineCode;
  
  const { data, error, isLoading, mutate } = useSWR<ILines>(
    lineCode ? `/client/lines/${lineCode}` : null,
    () => fetchData(lineCode as string | number),
    { revalidateOnFocus: false } 
  );

  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  };
};

export default useDataLines;
