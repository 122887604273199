import React, { FC, useState } from 'react';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Grid from '@mui/material/Grid2';
import useDataCompanyUsers from '../../hooks/useDataCompanyUsers';

interface FreeFieldProps {
    item: IUser
}

const FreeFieldCreate:FC<FreeFieldProps> = ({item}) => {
    const { createFreeField } = useDataCompanyUsers();
    const [open, setOpen] = useState(false)
    const [keyword, setKeyWord] = useState('')
    const [acceptOne, setAcceptOne] = useState(false)
    const [valueWord, setValueWord] = useState('')
    const [acceptTwo, setAcceptTwo] = useState(false)
    const [acceptThree, setAcceptThree] = useState(false)

    const handleAccept = () => {
        if(!acceptOne && !acceptTwo && !acceptThree){
            setAcceptOne(!acceptOne)
        } else if(acceptOne && !acceptTwo && !acceptThree){
            setAcceptTwo(!acceptTwo)
            setAcceptThree(!acceptThree)
            createFreeField(keyword,valueWord,item.id)
            setKeyWord('')
            setValueWord('')
            setOpen(false)
            setAcceptThree(false)
            setAcceptTwo(false)
        } 
 
    }

    const handleClose = () => {
        if(!acceptOne && !acceptTwo && !acceptThree){
            setOpen(false)
        } 

        if(acceptOne && !acceptTwo && !acceptThree){
            setAcceptOne(false)
        }

        if(acceptOne && acceptTwo && !acceptThree){
            setAcceptTwo(false)
        }
    }

    return (
        <Box>
            {!open ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Button 
                        startIcon={
                            <AddCircleOutlineRoundedIcon/>
                        }
                        onClick={() => setOpen(!open)}
                    >
                    הוספת שדה
                    </Button>
                </Box>
            :
 
                <Grid container justifyContent="flex-start" alignItems="center">
                    {!acceptOne ?
                    <Grid size={4} sx={{display:'flex', gap:'5px'}}>
                        <TextField sx={{minWidth:'150px'}} placeholder='אחר' value={keyword} onChange={(e) => setKeyWord(e.target.value)}/>
                        <IconButton onClick={() => handleClose()}>
                            <CloseRoundedIcon/>
                        </IconButton>
                        <IconButton onClick={() => handleAccept()}>
                            <CheckCircleOutlineRoundedIcon/>
                        </IconButton>
                    </Grid>
                    : 
                    <Grid  size={4}>
                        <Typography>
                            {keyword}
                        </Typography>
                    </Grid>
                    }
                    {(acceptOne && !acceptTwo) ?
                        <Grid size={6} sx={{display:'flex', gap:'5px'}}>
                            <TextField sx={{minWidth:'150px'}} placeholder='אחר' value={valueWord} onChange={(e) => setValueWord(e.target.value)}/>
                            <IconButton onClick={() => handleClose()} sx={{padding:'0px'}}>
                                <CloseRoundedIcon/>
                            </IconButton>
                            <IconButton onClick={() => handleAccept()} sx={{padding:'0px'}}>
                                <CheckCircleOutlineRoundedIcon/>
                            </IconButton>
                        </Grid>
                        :
                        <Grid  size={6}>
                            <Typography>
                                {valueWord}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            }

        </Box>
    );
};

export default FreeFieldCreate;

