import { Box } from '@mui/material';
import React from 'react';
import Catalog from '../components/Catalog';
import Layout from '../components/Layout';

const CatalogPage = () => {
    return (
        <Layout>
            <Catalog.Filter/>
            <Catalog.LastOrders/>
            <Catalog.Options/>
            <Catalog.CatalogList/>
        </Layout>
    );
};

export default CatalogPage;