import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCart } from '../../store/cart.store';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { useArea } from '../../store/area.store';
import { useNotify } from '../../provider/NotifyProvider';
import useDataLines from '../../hooks/useDataLines';

const MyIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21 10H3M16 2V6M8 2V6M10.5 14L12 13V18M10.75 18H13.25M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
            stroke="#2F4B6C"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


const Calendar = () => {
    const { deliveryDate, setDeliveryDate, sendOrder } = useCart();
    const { data } = useDataLines(); // data: ILines | undefined
    const navigate = useNavigate();
    const { user } = useAuth();
    const { choosedArea } = useArea();
    const { onErrorNotify, onSuccessNotify } = useNotify();
    const [openPicker, setOpenPicker] = useState(false);

    // Function to calculate the next allowed date
    const getNextAllowedDate = () => {
        if (!data) return null; // If data is not loaded yet

        const today = moment();
        for (let i = 0; i < 7; i++) {
            const nextDate = today.clone().add(i, 'days');
            const dayOfWeek = nextDate.isoWeekday(); // 1 for Monday, 7 for Sunday
            const daysMapping: Record<number, keyof ILines> = {
                1: 'MONDAY',
                2: 'TUESDAY',
                3: 'WEDNESDAY',
                4: 'THURSDAY',
                5: 'FRIDAY',
                6: 'SATURDAY',
                7: 'SUNDAY',
            };
            const dayKey = daysMapping[dayOfWeek];
            if (data[dayKey]) {
                return nextDate.toDate();
            }
        }

        return null; // No allowed date found (unlikely if data is correct)
    };

    // Set the next allowed date when the component mounts or data changes
    useEffect(() => {
        if (data) {
            const nextDate = getNextAllowedDate();
            if (nextDate) {
                setDeliveryDate(nextDate);
            }
        }
    }, [data]);

    const handleSendOrder = () => {
        if (!user?.permission?.permissionDetaileds[0]?.isOrderable) {
            onErrorNotify('אין הרשאה לבצע הזמנה');
            return;
        }
        sendOrder(user!, deliveryDate, choosedArea!).then((e) => {
            if (e?.status === 'success') {
                navigate(`${URLS.DOCUMENTS_ITEM.LINK}/${e.data?.documentNumber}`);
                onSuccessNotify(e.message);
            } else {
                onErrorNotify(e.message);
            }
        });
    };

    const handleDateChange = (newValue: Moment | null) => {
        setDeliveryDate(new Date(newValue?.toString()!));
        setOpenPicker(false);
    };

    const shouldDisableDate = (date: Moment) => {
        if (!data) {
            return true; // Disable all dates if data is not loaded
        }

        const dayOfWeek = date.isoWeekday(); // 1 for Monday, 7 for Sunday
        const daysMapping: Record<number, keyof ILines> = {
            1: 'MONDAY',
            2: 'TUESDAY',
            3: 'WEDNESDAY',
            4: 'THURSDAY',
            5: 'FRIDAY',
            6: 'SATURDAY',
            7: 'SUNDAY',
        };

        const dayKey = daysMapping[dayOfWeek];
        return !data[dayKey]; // Disable if the day is not enabled
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography fontWeight={700} fontSize={'15px'} color='#154D72'>
                בחרו תאריך למשלוח
            </Typography>
            <Typography fontWeight={400} fontSize={'15px'} color='#154D72'>
                בחרו תאריך מתוך התאריכים הזמינים
            </Typography>
            <Box
                onClick={() => setOpenPicker(true)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    justifyContent: 'space-between',
                    bgcolor: 'white',
                    borderRadius: '14px',
                    padding: '12px 12px 12px 10px',
                    border: '1px solid #80A8B6',
                    mt: '20px',
                }}
            >
                <Typography
                    color='#546D79'
                    fontSize={'13px'}
                    lineHeight={'19px'}
                    fontWeight={400}
                >
                    {moment(deliveryDate).format('DD/MM/YYYY')}
                </Typography>
                <MyIcon />
            </Box>
            <Box sx={{ margin: '20px 0' }}>
                {openPicker && (
                    <DatePicker
                        open={!!openPicker}
                        onClose={() => setOpenPicker(false)}
                        value={moment(deliveryDate)}
                        onChange={(e) => handleDateChange(e)}
                        shouldDisableDate={shouldDisableDate}
                        slotProps={{
                            actionBar: {
                                actions: [],
                            },
                        }}
                    />
                )}
            </Box>
            <Box sx={{ marginBottom: '20px' }}>
                <TextField multiline rows={2} placeholder='הוסיפו הערה...' fullWidth />
            </Box>
            <Button variant='contained' fullWidth onClick={() => handleSendOrder()}>
                אישור הזמנה
            </Button>
        </Box>
    );
};

export default Calendar;