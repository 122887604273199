import { Badge, BottomNavigation, BottomNavigationAction } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useCart } from '../../store/cart.store';
import useDataNotifications from '../../hooks/useDataNotifications';
import useDataLastServices from '../../hooks/useDataLastServices';

const CustomBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
    minWidth:'70px',
    '& svg': {
        transition: 'stroke 0.3s, transform 0.3s',
    },
    '&:hover svg': {
        stroke: theme.palette.primary.main, 
        transform: 'scale(1.1)',
    },
    '& .MuiBottomNavigationAction-label': {
        fontSize: '10px', 
        fontWeight: 700,
        color: 'var(--Deselected-Menu-Items, rgba(67, 111, 124, 0.50))',
    },
}));
const First = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0018 15.5C8.83173 15.5 6.0126 17.0306 4.2178 19.406C3.83151 19.9172 3.63836 20.1728 3.64468 20.5183C3.64956 20.7852 3.81716 21.1219 4.02717 21.2867C4.29899 21.5 4.67567 21.5 5.42904 21.5H18.5746C19.3279 21.5 19.7046 21.5 19.9764 21.2867C20.1864 21.1219 20.354 20.7852 20.3589 20.5183C20.3652 20.1728 20.1721 19.9172 19.7858 19.406C17.991 17.0306 15.1719 15.5 12.0018 15.5Z" stroke="currentColor" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.0018 12.5C14.4871 12.5 16.5018 10.4853 16.5018 8C16.5018 5.51472 14.4871 3.5 12.0018 3.5C9.51652 3.5 7.5018 5.51472 7.5018 8C7.5018 10.4853 9.51652 12.5 12.0018 12.5Z" stroke="currentColor" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const FirstClicked = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Component 1">
            <g id="Icon">
                <path 
                    d="M11.9999 15C8.82977 15 6.01065 16.5306 4.21585 18.906C3.82956 19.4172 3.63641 19.6728 3.64273 20.0183C3.64761 20.2852 3.81521 20.6219 4.02522 20.7867C4.29704 21 4.67372 21 5.42708 21H18.5726C19.326 21 19.7027 21 19.9745 20.7867C20.1845 20.6219 20.3521 20.2852 20.357 20.0183C20.3633 19.6728 20.1701 19.4172 19.7839 18.906C17.9891 16.5306 15.1699 15 11.9999 15Z" 
                    stroke="#2F4B6C" 
                    style={{
                        stroke: "#2F4B6C",
                        strokeOpacity: 1,
                    }}
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                />
                <path 
                    d="M11.9999 12C14.4851 12 16.4999 9.98528 16.4999 7.5C16.4999 5.01472 14.4851 3 11.9999 3C9.51457 3 7.49985 5.01472 7.49985 7.5C7.49985 9.98528 9.51457 12 11.9999 12Z" 
                    stroke="#2F4B6C" 
                    style={{
                        stroke: "#2F4B6C",
                        strokeOpacity: 1,
                    }}
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
);


const Second = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2487 19.5C15.2487 21.1569 13.9056 22.5 12.2487 22.5C10.5919 22.5 9.24874 21.1569 9.24874 19.5M14.0452 6.73856C14.4807 6.28864 14.7487 5.67562 14.7487 5C14.7487 3.61929 13.6295 2.5 12.2487 2.5C10.868 2.5 9.74874 3.61929 9.74874 5C9.74874 5.67562 10.0167 6.28864 10.4522 6.73856M18.2487 11.7C18.2487 10.3209 17.6166 8.99823 16.4914 8.02304C15.3662 7.04786 13.84 6.5 12.2487 6.5C10.6574 6.5 9.13132 7.04786 8.0061 8.02304C6.88089 8.99823 6.24874 10.3209 6.24874 11.7C6.24874 13.9818 5.68288 15.6506 4.97681 16.8447C4.17208 18.2056 3.76972 18.8861 3.78561 19.0486C3.80379 19.2346 3.83726 19.2933 3.98808 19.4036C4.11991 19.5 4.78225 19.5 6.10693 19.5H18.3906C19.7152 19.5 20.3776 19.5 20.5094 19.4036C20.6602 19.2933 20.6937 19.2346 20.7119 19.0486C20.7278 18.8861 20.3254 18.2056 19.5207 16.8447C18.8146 15.6506 18.2487 13.9818 18.2487 11.7Z" stroke="#547979" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const SecondClicked = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M15.2497 19C15.2497 20.6569 13.9066 22 12.2497 22C10.5929 22 9.24972 20.6569 9.24972 19M14.0462 6.23856C14.4817 5.78864 14.7497 5.17562 14.7497 4.5C14.7497 3.11929 13.6304 2 12.2497 2C10.869 2 9.74972 3.11929 9.74972 4.5C9.74972 5.17562 10.0177 5.78864 10.4532 6.23856M18.2497 11.2C18.2497 9.82087 17.6176 8.49823 16.4924 7.52304C15.3671 6.54786 13.841 6 12.2497 6C10.6584 6 9.1323 6.54786 8.00708 7.52304C6.88186 8.49823 6.24972 9.82087 6.24972 11.2C6.24972 13.4818 5.68385 15.1506 4.97778 16.3447C4.17306 17.7056 3.7707 18.3861 3.78659 18.5486C3.80476 18.7346 3.83824 18.7933 3.98906 18.9036C4.12089 19 4.78323 19 6.10791 19H18.3915C19.7162 19 20.3786 19 20.5104 18.9036C20.6612 18.7933 20.6947 18.7346 20.7129 18.5486C20.7287 18.3861 20.3264 17.7056 19.5217 16.3447C18.8156 15.1506 18.2497 13.4818 18.2497 11.2Z" 
            stroke="#2F4B6C" 
            style={{
                stroke: "#2F4B6C",
                strokeOpacity: 1,
            }}
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </svg>
);

const Third = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 16.5C14.1569 16.5 15.5 15.1568 15.5 13.5C15.5 11.8431 14.1569 10.5 12.5 10.5C10.8431 10.5 9.5 11.8431 9.5 13.5C9.5 15.1568 10.8431 16.5 12.5 16.5Z" stroke="#547979" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.5 11.0651C3.5 10.4907 3.5 10.2035 3.57403 9.93905C3.6396 9.70478 3.74737 9.48444 3.89203 9.28886C4.05534 9.06806 4.28202 8.89175 4.73539 8.53912L11.5177 3.264C11.869 2.99075 12.0447 2.85412 12.2387 2.8016C12.4098 2.75526 12.5902 2.75526 12.7613 2.8016C12.9553 2.85412 13.131 2.99075 13.4823 3.264L20.2646 8.53913C20.718 8.89175 20.9447 9.06806 21.108 9.28886C21.2526 9.48444 21.3604 9.70478 21.426 9.93905C21.5 10.2035 21.5 10.4907 21.5 11.0651V18.3C21.5 19.4201 21.5 19.9801 21.282 20.408C21.0903 20.7843 20.7843 21.0903 20.408 21.282C19.9802 21.5 19.4201 21.5 18.3 21.5H6.7C5.57989 21.5 5.01984 21.5 4.59202 21.282C4.21569 21.0903 3.90973 20.7843 3.71799 20.408C3.5 19.9801 3.5 19.4201 3.5 18.3V11.0651Z" stroke="#547979" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const ThirdClicked = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Component 1">
            <g id="Icon">
                <path 
                    d="M12.5 16C14.1569 16 15.5 14.6568 15.5 13C15.5 11.3431 14.1569 9.99998 12.5 9.99998C10.8431 9.99998 9.5 11.3431 9.5 13C9.5 14.6568 10.8431 16 12.5 16Z" 
                    stroke="#2F4B6C" 
                    style={{
                        stroke: "#2F4B6C",
                        strokeOpacity: 1,
                    }} 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                />
                <path 
                    d="M3.5 10.5651C3.5 9.9907 3.5 9.70352 3.57403 9.43905C3.6396 9.20478 3.74737 8.98444 3.89203 8.78886C4.05534 8.56806 4.28202 8.39175 4.73539 8.03912L11.5177 2.764C11.869 2.49075 12.0447 2.35412 12.2387 2.3016C12.4098 2.25526 12.5902 2.25526 12.7613 2.3016C12.9553 2.35412 13.131 2.49075 13.4823 2.764L20.2646 8.03913C20.718 8.39175 20.9447 8.56806 21.108 8.78886C21.2526 8.98444 21.3604 9.20478 21.426 9.43905C21.5 9.70352 21.5 9.9907 21.5 10.5651V17.8C21.5 18.9201 21.5 19.4801 21.282 19.908C21.0903 20.2843 20.7843 20.5903 20.408 20.782C19.9802 21 19.4201 21 18.3 21H6.7C5.57989 21 5.01984 21 4.59202 20.782C4.21569 20.5903 3.90973 20.2843 3.71799 19.908C3.5 19.4801 3.5 18.9201 3.5 17.8V10.5651Z" 
                    stroke="#2F4B6C" 
                    style={{
                        stroke: "#2F4B6C",
                        strokeOpacity: 1,
                    }} 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
)

const Fourth = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M5.75014 14.5H18.8859C19.8987 14.5 20.4051 14.5 20.8082 14.3112C21.1634 14.1448 21.4618 13.8777 21.6663 13.5432C21.8985 13.1633 21.9544 12.66 22.0663 11.6534L22.6513 6.38835C22.6855 6.08088 22.7025 5.92715 22.6531 5.80816C22.6097 5.70366 22.5321 5.61697 22.433 5.56228C22.3202 5.5 22.1655 5.5 21.8562 5.5H5.25014M2.75 2.5H3.99844C4.26306 2.5 4.39537 2.5 4.49889 2.55032C4.59002 2.59463 4.66554 2.66557 4.71544 2.75376C4.77212 2.85394 4.78037 2.98599 4.79688 3.2501L5.70312 17.7499C5.71963 18.014 5.72788 18.1461 5.78456 18.2462C5.83446 18.3344 5.90998 18.4054 6.00111 18.4497C6.10463 18.5 6.23694 18.5 6.50156 18.5H19.75M8.25 22H8.26M17.25 22H17.26M8.75 22C8.75 22.2761 8.52614 22.5 8.25 22.5C7.97386 22.5 7.75 22.2761 7.75 22C7.75 21.7239 7.97386 21.5 8.25 21.5C8.52614 21.5 8.75 21.7239 8.75 22ZM17.75 22C17.75 22.2761 17.5261 22.5 17.25 22.5C16.9739 22.5 16.75 22.2761 16.75 22C16.75 21.7239 16.9739 21.5 17.25 21.5C17.5261 21.5 17.75 21.7239 17.75 22Z" 
            stroke="#547979" 
            strokeOpacity="0.5" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </svg>
);

const ForthClicked = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Component 1">
            <path 
                id="Icon" 
                d="M5.75014 14H18.8859C19.8987 14 20.4051 14 20.8082 13.8112C21.1634 13.6448 21.4618 13.3777 21.6663 13.0432C21.8985 12.6633 21.9544 12.16 22.0663 11.1534L22.6513 5.88835C22.6855 5.58088 22.7025 5.42715 22.6531 5.30816C22.6097 5.20366 22.5321 5.11697 22.433 5.06228C22.3202 5 22.1655 5 21.8562 5H5.25014M2.75 2H3.99844C4.26306 2 4.39537 2 4.49889 2.05032C4.59002 2.09463 4.66554 2.16557 4.71544 2.25376C4.77212 2.35394 4.78037 2.48599 4.79688 2.7501L5.70312 17.2499C5.71963 17.514 5.72788 17.6461 5.78456 17.7462C5.83446 17.8344 5.90998 17.9054 6.00111 17.9497C6.10463 18 6.23694 18 6.50156 18H19.75M8.25 21.5H8.26M17.25 21.5H17.26M8.75 21.5C8.75 21.7761 8.52614 22 8.25 22C7.97386 22 7.75 21.7761 7.75 21.5C7.75 21.2239 7.97386 21 8.25 21C8.52614 21 8.75 21.2239 8.75 21.5ZM17.75 21.5C17.75 21.7761 17.5261 22 17.25 22C16.9739 22 16.75 21.7761 16.75 21.5C16.75 21.2239 16.9739 21 17.25 21C17.5261 21 17.75 21.2239 17.75 21.5Z" 
                stroke="#2F4B6C" 
                style={{
                    stroke: "#2F4B6C",
                    strokeOpacity: 1,
                }}
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </g>
    </svg>
);


const Fifth = () => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 6.5L10.5 11M6 6.5H3L2 3.5L3 2.5L6 3.5V6.5ZM19.259 3.24101L16.6314 5.86863C16.2354 6.26465 16.0373 6.46265 15.9632 6.69098C15.8979 6.89183 15.8979 7.10817 15.9632 7.30902C16.0373 7.53735 16.2354 7.73535 16.6314 8.13137L16.8686 8.36863C17.2646 8.76465 17.4627 8.96265 17.691 9.03684C17.8918 9.1021 18.1082 9.1021 18.309 9.03684C18.5373 8.96265 18.7354 8.76465 19.1314 8.36863L21.5893 5.91072C21.854 6.55488 22 7.26039 22 8C22 11.0376 19.5376 13.5 16.5 13.5C16.1338 13.5 15.7759 13.4642 15.4298 13.3959C14.9436 13.3001 14.7005 13.2521 14.5532 13.2668C14.3965 13.2824 14.3193 13.3059 14.1805 13.3802C14.0499 13.4501 13.919 13.581 13.657 13.843L6.5 21C5.67157 21.8284 4.32843 21.8284 3.5 21C2.67157 20.1716 2.67157 18.8284 3.5 18L10.657 10.843C10.919 10.581 11.0499 10.4501 11.1198 10.3195C11.1941 10.1807 11.2176 10.1035 11.2332 9.94681C11.2479 9.79945 11.1999 9.55638 11.1041 9.07024C11.0358 8.72406 11 8.36621 11 8C11 4.96243 13.4624 2.5 16.5 2.5C17.5055 2.5 18.448 2.76982 19.259 3.24101ZM12.0001 15.4999L17.5 20.9999C18.3284 21.8283 19.6716 21.8283 20.5 20.9999C21.3284 20.1715 21.3284 18.8283 20.5 17.9999L15.9753 13.4753C15.655 13.445 15.3427 13.3872 15.0408 13.3043C14.6517 13.1975 14.2249 13.2751 13.9397 13.5603L12.0001 15.4999Z"
            stroke="#547979"
            strokeOpacity="0.5"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const FifthClicked = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Component 1">
            <path 
                id="Icon" 
                d="M6 6L10.5 10.5M6 6H3L2 3L3 2L6 3V6ZM19.259 2.74101L16.6314 5.36863C16.2354 5.76465 16.0373 5.96265 15.9632 6.19098C15.8979 6.39183 15.8979 6.60817 15.9632 6.80902C16.0373 7.03735 16.2354 7.23535 16.6314 7.63137L16.8686 7.86863C17.2646 8.26465 17.4627 8.46265 17.691 8.53684C17.8918 8.6021 18.1082 8.6021 18.309 8.53684C18.5373 8.46265 18.7354 8.26465 19.1314 7.86863L21.5893 5.41072C21.854 6.05488 22 6.76039 22 7.5C22 10.5376 19.5376 13 16.5 13C16.1338 13 15.7759 12.9642 15.4298 12.8959C14.9436 12.8001 14.7005 12.7521 14.5532 12.7668C14.3965 12.7824 14.3193 12.8059 14.1805 12.8802C14.0499 12.9501 13.919 13.081 13.657 13.343L6.5 20.5C5.67157 21.3284 4.32843 21.3284 3.5 20.5C2.67157 19.6716 2.67157 18.3284 3.5 17.5L10.657 10.343C10.919 10.081 11.0499 9.95005 11.1198 9.81949C11.1941 9.68068 11.2176 9.60347 11.2332 9.44681C11.2479 9.29945 11.1999 9.05638 11.1041 8.57024C11.0358 8.22406 11 7.86621 11 7.5C11 4.46243 13.4624 2 16.5 2C17.5055 2 18.448 2.26982 19.259 2.74101ZM12.0001 14.9999L17.5 20.4999C18.3284 21.3283 19.6716 21.3283 20.5 20.4999C21.3284 19.6715 21.3284 18.3283 20.5 17.4999L15.9753 12.9753C15.655 12.945 15.3427 12.8872 15.0408 12.8043C14.6517 12.6975 14.2249 12.7751 13.9397 13.0603L12.0001 14.9999Z" 
                stroke="#2F4B6C" 
                style={{
                    stroke: "#2F4B6C",
                    strokeOpacity: 1,
                }}
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

const HorizontalDivider = () => (
    <svg width="31" height="3" viewBox="0 0 31 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect 
            x="0.5" 
            width="30" 
            height="3" 
            rx="1.5" 
            fill="#2F4B6C" 
            style={{
                fill: "#2F4B6C",
                fillOpacity: 1,
            }}
        />
    </svg>
);

const FooterNavigation = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { cart } = useCart()
    const { data } = useDataNotifications()
    const { data: services } = useDataLastServices()
    return (
        <BottomNavigation
            showLabels
            sx={{
                borderRadius: "30px 30px 0px 0px",
                height: '102px',
                padding: '0px 40px 5px 40px',
                background: 'var(--white-base-cards, #FFF)',
                boxShadow: '0px -2px 7px 0px rgba(0, 0, 0, 0.10)',
                '@supports (color: display-p3)': {
                    background: 'var(--white-base-cards, color(display-p3 1 1 1))',
                    boxShadow: '0px -2px 7px 0px color(display-p3 0 0 0 / 0.10)'
                }
            }}
        >
            <CustomBottomNavigationAction 
                label={pathname === URLS.SERVICE.LINK ? <HorizontalDivider /> : "מכונות"} 
                icon={
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                transform: 'translate(-14px, -14px)', 
                            },
                        }}
                        badgeContent={services?.length} 
                        color='info'
                    >
                        {pathname === URLS.SERVICE.LINK ? <FifthClicked />  : <Fifth />}
                    </Badge> 
                } 
                onClick={() => navigate(URLS.SERVICE.LINK)}
            />
            <CustomBottomNavigationAction 
                label={pathname === URLS.CATALOG.LINK ? <HorizontalDivider /> : "הזמנות"} 
                icon={
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                transform: 'translate(-14px, -14px)', 
                            },
                        }}
                        badgeContent={cart.length} 
                        color='info'
                    >
                        {pathname === URLS.CATALOG.LINK ? <ForthClicked />  : <Fourth />}
                    </Badge>
                } 
                onClick={() => navigate(URLS.CATALOG.LINK)}
            />
            <CustomBottomNavigationAction 
                label={pathname === URLS.HOME.LINK ? <HorizontalDivider /> : "בית"} 
                icon={pathname === URLS.HOME.LINK ? <ThirdClicked /> : <Third />} 
                onClick={() => navigate(URLS.HOME.LINK)}
            />
            <CustomBottomNavigationAction 
                label={pathname === URLS.NOTIFICATIONS.LINK ? <HorizontalDivider /> : "התראות"}
                icon={
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiBadge-badge': {
                                transform: 'translate(-14px, -14px)', 
                            },
                        }}
                        badgeContent={data?.filter((item) => !item?.read).length} 
                        color='info'
                    >
                        {pathname === URLS.NOTIFICATIONS.LINK ? <SecondClicked />  : <Second />}
                    </Badge>
              } 
                onClick={() => navigate(URLS.NOTIFICATIONS.LINK)}
            />
            <CustomBottomNavigationAction 
                label={pathname === URLS.MENU_PAGE.LINK ? <HorizontalDivider /> : "איזור אישי"}
                icon={pathname === URLS.MENU_PAGE.LINK ? <FirstClicked /> : <First />} 
                onClick={() => navigate(URLS.MENU_PAGE.LINK)}
            />
        </BottomNavigation>
    );
};

export default FooterNavigation;
