import { ApiInterceptor } from "./api.interceptor";

export const DocumentService = {
    async getDocuments(
        documentType: string,
        userExtId: string,
        area: string,
        dateFrom: string,
        dateTo: string,
        areas?: string
    ): Promise<IDocument[]> {
        let url = `/client/documents/${documentType}/${userExtId}/${area}/${dateFrom}/${dateTo}`;
        if (areas) {
            url += `?areas=${areas}`;
        }
        const response = await ApiInterceptor<IDocument[]>(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        
        return response;
    },
    async getDocumentItems(documentNumber: string): Promise<IDocument>{
        const response = await ApiInterceptor<IDocument>(`/client/documents/orders/${documentNumber}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async getLastOrders(userExtId: string, areaCode: string,isAvaliable: boolean = false): Promise<IDocument[]>{
        const response = await ApiInterceptor<IDocument[]>(`/client/lastOrders/${areaCode}/${userExtId}${isAvaliable ? '?type=avaliable' :''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async updateOrder(documentNumber: string,isDelete: boolean, dueDate: string, comment: string, cart?: ICart[]): Promise<ApiResponse>{
        const response = await ApiInterceptor<ApiResponse>(`/client/documents/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({documentNumber,isDelete,dueDate,comment,cart})
        });
        return response
    },

    async sendOrder( user: IUser, deliveryDate: Date, area: IArea, cart: ICart[]): Promise<ISendOrderResponse>{
        const response = await ApiInterceptor<ISendOrderResponse>(`/client/sendOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({user,deliveryDate,area,cart})
        });
        return response
    },

    async getLastDocuments(area: string, userExtId: string,isAvaliable: boolean = false ): Promise<IDocument[]>{
        const response = await ApiInterceptor<IDocument[]>(`/client/LastDocuments/${area}/${userExtId}${isAvaliable ? '?type=avaliable' :''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

}