import { ApiInterceptor } from "./api.interceptor";

export const AreaService = {
    async getArea(userExtId: string, userId: string | number): Promise<IArea[]>{
        const response = await ApiInterceptor<IArea[]>(`/client/areas/${userExtId}/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async getLines(code: number | string) {
        const response = await ApiInterceptor<ILines>(`/client/lines/${code}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    }

}