import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { themeColors } from '../../styles';
import useDataNotifications from '../../hooks/useDataNotifications';
import useDataLastDocuments from '../../hooks/useDataLastDocuments';
import LastSkeleton from '../../utils/LastDocs/LastSkeleton';
import Card from './Card';
import { DocumentEnum } from '../../types/documentEnum';

const LastNews = () => {
    const {data, isLoading} = useDataLastDocuments()
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                עדכונים אחרונים
                </Typography>
                <IconButton>
                    <ArrowBackIosRoundedIcon sx={{ color: themeColors.title }} />
                </IconButton>
            </Box>
            {isLoading ? (
                [...Array(3)].map((_, index) => (
                    <LastSkeleton key={index} />
                ))
            ) : (
                <Box>
                    {data?.slice(0, 3).map((item) => (
                        <Card key={item.documentNumber} item={item} isOrder={item?.documentType === DocumentEnum.ORDERS} />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default LastNews;