import { Box, TextField, InputAdornment, Typography, Stack, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Import the icon you want to use
import React from 'react';
import { useCatalog } from '../../store/catalog.store';
import useDataCatalog from '../../hooks/useDataCatalog';
import ChipSelector from '../Common/ChipSelector';
import Grid from '@mui/material/Grid2';

const Filter = () => {
    const { search, setSearch, openFilter, selectedCategories, setSelectedCategoies, clearFilter } = useCatalog();
    const {data} = useDataCatalog()
    return (
        <Box sx={{padding:'0 10px'}}>
            <TextField
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{fontSize:'30px', color:'#8EA6B2'}}/>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    bgcolor:'white',
                    borderRadius:'14px'
                }}
                placeholder="חפשו לפי מוצר..." 
            />
            {openFilter &&
                <Box sx={{mt:'20px'}}>
                    <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                            סינון
                        </Typography>
                        <Box sx={{display:'flex', gap:'5px', alignItems:'center', cursor:'pointer'}} onClick={() => clearFilter()}>
                            <Typography color='info' sx={{textDecoration:'underline'}}>
                            בטל סינון
                            </Typography>
                            <img src={`${process.env.REACT_APP_MEDIA}/clearCart.svg`} />
                        </Box>
                    </Box>
                </Box>
            }
            {openFilter &&
                <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{mt:'10px'}}>
                    {data?.map((item) => (
                        <Grid key={item.category}>
                                <Chip
                                    color='primary'
                                    label={item.category}
                                    sx={{
                                        fontWeight: 700,
                                        fontSize:'12px',
                                        lineHeight:'19px',
                                        '&.MuiChip-filled': {
                                            backgroundColor: 'primary.main', 
                                            color: 'white', 
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedCategories.includes(item.category)) {
                                            setSelectedCategoies(selectedCategories.filter(category => category !== item.category));
                                        } else {
                                            setSelectedCategoies([...selectedCategories, item.category]); // This should be an array
                                        }
                                    }}
                                    variant={selectedCategories.includes(item.category) ? 'filled' : 'outlined'} // Update the variant check
                                />
                        </Grid>
                    ))}
                </Grid>
            }

        </Box>
    );
};

export default Filter;
