import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { ServicesService } from '../services/services.service';

const fetchData = async (documentNumber: string): Promise<IDocument> => {
  return await ServicesService.getDocumentSevice(documentNumber);
};

const useDataMachineDocument = () => {
  const { id } = useParams();
  
  const { data, error, isLoading, mutate } = useSWR<IDocument>(
    id ? `/client/service/${id}` : null,
    () => fetchData(id!)
  );

  return {
    data,
    isLoading: isLoading ?? false,
    isError: !!error,
    mutate,
  };
};

export default useDataMachineDocument;
