import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React from 'react';
import { useArea } from '../../store/area.store';
import { useAuth } from '../../store/auth.store';
import useDataArea from '../../hooks/useDataArea';
import { themeColors } from '../../styles';
import { useLocation } from 'react-router-dom';
import URLS from '../../enums/route';
import AreaSelector from '../../utils/AreaSelector';

const Header = () => {
    const { pathname } = useLocation()
    const {choosedArea,setChoosedArea} = useArea()
    const {user} = useAuth()
    const { data } = useDataArea()
    const isActiveProfile = pathname === URLS.HOME.LINK
    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string;
        const selectedArea = data!.find(item => item.code === selectedValue) || null;
        setChoosedArea(selectedArea);
    };
    return (
        <Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', mb:'15px'}}>
                <img src={`${process.env.REACT_APP_MEDIA}/logo.png`} />
            </Box>
            {isActiveProfile &&
                <List>
                    <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar src={`${process.env.REACT_APP_MEDIA_BACKEND}media/avatar/${user?.avatar?.image?.filePath}`} sx={{width:'50px', height:'50px'}}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Typography fontWeight={800} lineHeight={'21px'} fontStyle={'normal'} fontSize={'15px'} color={themeColors.title}>
                                    {user?.name}
                                </Typography>
                            }
                            secondary={
                                <AreaSelector/>
                            }
                        />
                    </ListItem>
                </List>
            }
        </Box>
    );
};

export default Header;