import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import SvgIcon from '@mui/material/SvgIcon';

// Custom SVG for unchecked state
function CustomUncheckedIcon(props:any) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21">
      <path
        d="M1 4.08166C1 2.42481 2.34315 1.08167 4 1.08167H16C17.6569 1.08167 19 2.42481 19 4.08167V16.0817C19 17.7385 17.6569 19.0817 16 19.0817H4C2.34315 19.0817 1 17.7385 1 16.0817V4.08166Z"
        fill="white"
      />
      <path
        d="M1 4.08166C1 2.42481 2.34315 1.08167 4 1.08167H16C17.6569 1.08167 19 2.42481 19 4.08167V16.0817C19 17.7385 17.6569 19.0817 16 19.0817H4C2.34315 19.0817 1 17.7385 1 16.0817V4.08166Z"
        stroke="#2F4B6C"
        fill="#F5F7FA"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}

// Custom SVG for checked state
function CustomCheckedIcon(props:any) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21" sx={{color:'#2F4B6C'}}>
      <rect
        y="0.081665"
        width="20"
        height="20"
        rx="4"
        fill="#2F4B6C"
      />
      <path
        d="M15 6.33167L8.125 13.2067L5 10.0817"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

// CustomCheckbox component
function CustomCheckbox(props:any) {
  return (
    <Checkbox
      icon={<CustomUncheckedIcon />}       // Custom unchecked icon
      checkedIcon={<CustomCheckedIcon />}   // Custom checked icon
      {...props}                            // Spread any additional props
    />
  );
}

export default CustomCheckbox;
