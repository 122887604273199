import React, { FC } from 'react'
import Modal from '@mui/material/Modal'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type ModalWrapperProps = {
  active: boolean
  setActive: (bool: boolean) => void
  children: any
  component?: React.ReactNode
  component2?: React.ReactNode
  zIndex?: number
}

const ModalWrapper: FC<ModalWrapperProps> = ({
  active,
  setActive,
  children,
  component,
  component2,
  zIndex,
}) => {

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:'15px',
    p: 2,
    width: `90%`,
    height: `auto`,
    maxHeight:'90%',
    zIndex: zIndex,
    overflow: 'auto',
  }

  return (
    <>
      <Modal open={active} onClose={() => setActive(false)} disableAutoFocus>
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems:'center',
              marginBottom: '30px',
            }}
          >
            <Box>{component}</Box>
            <Box sx={{ height: '100%' }}>
              {component2 ?
              
                component2

                :
                <IconButton onClick={() => setActive(false)}>
                  <CloseIcon sx={{ fontSize: '25px', cursor: 'pointer', color:'#2F4B6C' }} />
                </IconButton>
              }
   
            </Box>
          </Box>
          {children}
        </Box>
      </Modal>
    </>
  )
}

export default ModalWrapper
