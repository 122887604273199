import React from 'react';
import { Box } from '@mui/material';

const CustomCircularProgress = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: 'rotate 2s linear infinite', 
        '@keyframes rotate': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '50%': {
            transform: 'rotate(-180deg)', 
          },
          '100%': {
            transform: 'rotate(-360deg)', 
          },
        },
      }}
    >
      <svg
        width="53"
        height="52"
        viewBox="0 0 53 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transformOrigin: 'center', // Rotate around the center of the SVG
        }}
      >
        <g id="Group">
          <path
            id="Icon (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4311 19.0991C23.3364 15.1939 29.668 15.1939 33.5733 19.0991C34.9558 20.4817 35.8502 22.171 36.2536 23.9489C36.3758 24.4875 36.0382 25.0232 35.4996 25.1454C34.961 25.2676 34.4254 24.93 34.3032 24.3914C33.9808 22.9707 33.267 21.6213 32.159 20.5133C29.0349 17.3891 23.9695 17.3891 20.8453 20.5133C19.667 21.6917 18.8885 22.5532 18.3765 23.1702H22.5022C23.0545 23.1702 23.5022 23.6179 23.5022 24.1702C23.5022 24.7225 23.0545 25.1702 22.5022 25.1702H16.5022C15.9499 25.1702 15.5022 24.7225 15.5022 24.1702V18.1702C15.5022 17.6179 15.9499 17.1702 16.5022 17.1702C17.0545 17.1702 17.5022 17.6179 17.5022 18.1702V21.1238C18.0019 20.5653 18.6331 19.8971 19.4311 19.0991ZM17.5048 27.195C18.0434 27.0728 18.579 27.4103 18.7012 27.9489C19.0236 29.3696 19.7374 30.719 20.8453 31.827C23.9695 34.9512 29.0349 34.9512 32.159 31.827C33.3374 30.6486 34.1159 29.7871 34.6279 29.1702H30.5022C29.9499 29.1702 29.5022 28.7225 29.5022 28.1702C29.5022 27.6179 29.9499 27.1702 30.5022 27.1702H36.5022C37.0545 27.1702 37.5022 27.6179 37.5022 28.1702V34.1702C37.5022 34.7225 37.0545 35.1702 36.5022 35.1702C35.9499 35.1702 35.5022 34.7225 35.5022 34.1702V31.2166C35.0024 31.7751 34.3713 32.4432 33.5733 33.2412C29.668 37.1465 23.3364 37.1465 19.4311 33.2412C18.0486 31.8587 17.1542 30.1693 16.7508 28.3914C16.6286 27.8528 16.9662 27.3172 17.5048 27.195Z"
            fill="#2F4B6C"
          />
        </g>
      </svg>
    </Box>
  );
};

export default CustomCircularProgress;
