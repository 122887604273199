import { Box, CardMedia, Paper, Skeleton, Typography } from '@mui/material';
import React, { FC, useState } from 'react';

interface CardProps {
    item: IDocumentItem
}

const Card:FC<CardProps> = ({item}) => {
    const [loading, setLoading] = useState(true)

    const handleImageLoad = () => {
        setLoading(false)
    }

    return (
        <Box sx={{display:'flex', gap:'10px', justifyContent:'space-between', alignItems:'center', padding:'15px'}}>
            <Box sx={{display:'flex', gap:'10px'}}>
                {loading && (
                    <Skeleton
                        variant="rectangular"
                        sx={{
                            borderRadius: '15px',
                            width: '100px',
                            height: '100px',
                            flexShrink: 0 
                        }}
                    />
                )}
                <CardMedia
                    component="img"
                    image={item?.product?.image ? `${process.env.REACT_APP_MEDIA_BACKEND}/${item?.product?.image.filePath}` : `${process.env.REACT_APP_MEDIA}/placeholder.png`}
                    alt="Paella dish"
                    sx={{ 
                        width: '100px', 
                        height: '100px', 
                        borderRadius: '15px',
                        display: loading ? 'none' : 'block',
                        cursor: 'pointer',
                    }}
                    onLoad={handleImageLoad}
                />
                <Box>
                    <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                        {item?.title}
                    </Typography>
                    <Typography color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>
                        {item?.product?.packQuantity}
                    </Typography>
                </Box>
            </Box>
            <Typography color='#154D72' fontSize={'20px'} fontWeight={700} letterSpacing={'0.3px'} lineHeight={'18px'}>
            {item?.quantity}
            </Typography>
        </Box>
    );
};

export default Card;