import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import useDataSlider from '../../hooks/useDataSlider';
import Carousel from "react-material-ui-carousel";
import { themeColors } from '../../styles';

const Slider = () => {
    const { data } = useDataSlider();
    
    return (
        <Box sx={{mt:'30px'}}>
            <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                מה חדש?
            </Typography>
            <Carousel 
                sx={{
                    mt: '10px',
                }}
                navButtonsAlwaysVisible={true}
                navButtonsAlwaysInvisible={true}
            >
                {data?.map((item) => {
                    const baseURL = process.env.REACT_APP_MEDIA_BACKEND;
                    const source = encodeURIComponent(item.image.source);
                    const filePath = encodeURIComponent(item.image.filePath);
                    const encodedURL = `${baseURL}media/${source}/${filePath}`;
                    return (
                        <Box key={item.id} sx={{ position: 'relative', height: 200 }}> 
                        <Card sx={{ height: '100%', overflow: 'hidden' }}>
          
                            <CardContent
                                sx={{
                                    height: '100%',
                                    padding: '16px',
                                    borderRadius: '20px',
                                    backgroundImage: `
                                        linear-gradient(180deg, rgba(0, 58, 78, 0) 22.35%, rgba(0, 58, 78, 0.9) 70.2%), 
                                        linear-gradient(0deg, rgba(0, 58, 78, 0.2) 0%, rgba(0, 58, 78, 0.2) 100%), 
                                        url(${encodedURL})
                                    `,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundBlendMode: 'normal, color, normal',
                                    color: 'white',
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'flex-end', 
                                    alignItems: 'flex-start', 
                                }}
                            >
                                <Box>
                                    <Typography variant="h5" component="div" fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="white" fontSize={'13px'} fontWeight={400}>
                                        {item.description}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    )
                })}
            </Carousel>
        </Box>
    );
};

export default Slider;
