import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { NotificationService } from '../services/notifications.service'

const fetchData = async (
  userId: string | number,
): Promise<INotification[]> => {
  return await NotificationService.getNotification(userId)
}

const useDataNotifications = () => {
  const { user } = useAuth()
  const { data, error, isLoading, mutate } = useSWR<INotification[]>(
    `/client/notification/${user?.id}`,
    () => fetchData(user?.id!)
  )

  const readNotification = async (id: string | number) => {
    await NotificationService.readNotification(id)
    mutate()
  }
  
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
    readNotification
  }
}

export default useDataNotifications

