import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    Link,
} from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';
import CustomCheckbox from '../Common/CUstomCheckBox';
import Turnstile, { useTurnstile } from "react-turnstile";
import { useNotify } from '../../provider/NotifyProvider';

const AuthStepOneForm = () => {
    const navigate = useNavigate()
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [error, setError] = useState('')
    const {onErrorNotify} = useNotify()
    const { validationStepOne, setUserExtId, setPhone } = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        watch,
    } = useForm<IAuthStepOne>({
        mode: 'onChange', // enables validation tracking
    })

    // Watch the state of the checkbox
    const privacyChecked = watch('privacy', false);

    const onSubmit = async (data: IAuthStepOne) => {
        const isDev = process.env.NODE_ENV === 'development';
        console.log('isDev',isDev)
        if (!captchaToken && !isDev) {
            onErrorNotify('אימות קפצ׳ה נכשל');
            return;
        }
        setError('')
        try {
            setUserExtId(data?.userExtId)
            setPhone(data?.phone)
            const response = await validationStepOne(captchaToken!)
            if(response?.status === 'success'){
                navigate(URLS.AuthStepTwo.LINK)
            } else {
                setError(response.message);
            }
        } catch (e) {
            console.error("[ERROR]", e)
            setError('שגיאה');
        } 
    }
    
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                    הרשמה למערכת
                </Typography>
                <Typography variant="body1" color='#436F7C'>
                כתבו את
                <Link  underline="always" sx={{ display: 'inline', padding:'0 5px', cursor:'pointer' }}>
                    <Typography variant="body1" component="span" color={themeColors.primary}>
                    מספר הלקוח ( ? )
                    </Typography>
                </Link>
                שלכם<br/>
                והטלפון של איש הקשר.
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'טלפון שדה חובה',
                                minLength: {
                                    value: 10,
                                    message: 'טלפון חייב להכיל לפחות 10 תווים',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    placeholder="טלפון*"
                                    type="tel"
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <Controller
                            name="userExtId"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'קוד משתמש',
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    placeholder="הכנס את המספר שלך"
                                    type="text"
                                    error={!!errors.userExtId}
                                    helperText={errors.userExtId?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControlLabel
                        sx={{ marginTop: '10px' }}
                        control={
                            <Controller
                                name="privacy"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => <CustomCheckbox {...field}/>}
                            />
                        }
                        label={
                            <>
                                <Typography variant="body1" color='#436F7C'>
                                קראתי והסכמתי ל
                                <Link href="#" underline="always" sx={{ display: 'inline', padding:'0 5px' }}>
                                    <Typography variant="body1" component="span" color={themeColors.primary}>
                                    תנאי השימוש
                                    </Typography>
                                </Link>
                                </Typography>
                            </>
                        }
                    />
                    {error && (
                        <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>
                            {error}
                        </Typography>
                    )}
                </form>
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Turnstile
                        sitekey={process.env.REACT_APP_CLOUDFLARE!}
                        onVerify={(token) => setCaptchaToken(token)}
                    />
                </Box>
            </Box>
      
            <Box sx={{ bottom: 0, width: '100%' }}>
                <Box sx={{ display: 'flex', gap: '5px', textAlign:'center', justifyContent:'center', marginBottom:'30px'}}>
                    <Typography variant="body1" color='#436F7C'>
                        קראתי והסכמתי ל
                        <Link  underline="always" sx={{ display: 'inline', padding:'0 5px', cursor:'pointer' }} onClick={() => navigate(URLS.RESET_PASSWORD_STEP_ONE.LINK)}>
                            <Typography variant="body1" component="span" color={themeColors.primary}>
                            בואו נטפל בזה
                            </Typography>
                        </Link>
                    </Typography>
                </Box>
                <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid || !privacyChecked} // Disable if form is not valid or checkbox is not checked
                >
                    המשך
                </Button>
            </Box>
        </Box>
    );
};

export default AuthStepOneForm;
