import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';
import { useArea } from '../../store/area.store';

const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login, setUser } = useAuth();
    const {setChoosedArea} = useArea()
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        reset,
    } = useForm<ILogin>({ mode: 'onChange' });

    const onSubmit = async (data: ILogin) => {
        setError('');
        try {
            const response = await login(data);
            if(response.status === 'success'){
                setChoosedArea(response.area[0])
                setUser(response.user);
                navigate(URLS.HOME.LINK);
                reset();
            } else {
                setError(response.message);
            }
        } catch(e) {
            console.error("[ERROR]", e);
            setError("שגיאה");
        } 
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography textAlign={'center'} fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                    שנתחבר?
                </Typography>
                <FormControl fullWidth margin="normal" sx={{ mt: "24px", mb: "0px" }}>
                    <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "מייל שדה חובה",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "מייל אינו תקין",
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                placeholder="אימייל *"
                                error={!!errors.username}
                                helperText={errors.username?.message}
                            />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal" sx={{ mt: '20px' }}>
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'סיסמא שדה חובה',
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                placeholder="הסיסמה שלך"
                                type={showPassword ? 'text' : 'password'}
                                error={!!errors.password}
                                helperText={errors.password?.message}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            {showPassword ? (
                                                <VisibilityOffOutlinedIcon color="primary" />
                                            ) : (
                                                <RemoveRedEyeOutlinedIcon color="primary" />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>
                <Box sx={{ display: 'flex', gap: '5px', textAlign: 'center', justifyContent: 'center', mt: '20px' }}>
                    <Typography>שכחתם סיסמא?</Typography>
                    <Typography sx={{ textDecoration: 'underline', color: '#00A293', cursor: 'pointer' }} onClick={() => navigate(URLS.RESET_PASSWORD_STEP_ONE.LINK)}>
                        בואו נטפל בזה
                    </Typography>
                </Box>
                {error && (
                    <Typography color="error" sx={{ textAlign: 'center', mt: '20px' }}>
                        {error}
                    </Typography>
                )}
            </Box>
            <Box>
                <Button variant='contained' fullWidth type='submit' disabled={!isValid || isSubmitting}>
                    המשך
                </Button>
            </Box>
        </form>
    );
};

export default LoginForm;
