import React, { useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useDocuments } from '../../store/documents.store';
import { useParams } from 'react-router-dom';
import { DocumentEnum } from '../../types/documentEnum';

const MyIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 10H3M16 2V6M8 2V6M10.5 14L12 13V18M10.75 18H13.25M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
        stroke="#2F4B6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

const Filter = () => {
    const { dateFrom, dateTo, setDateFrom, setDateTo } = useDocuments();
    const [openPicker, setOpenPicker] = useState<'from' | 'to' | null>(null);
    const {documentType} = useParams()
    
    const handleDateChange = (newValue: Moment | null) => {
        if (!newValue) return;

        if (openPicker === 'from') {
            setDateFrom(newValue.toDate());
        } else if (openPicker === 'to') {
            setDateTo(newValue.toDate());
        }

        setOpenPicker(null);
    };


    const handleTitle = () => {
        if(documentType == DocumentEnum.SERVICES){
            return 'קריאות שירות'
        } else if(documentType == DocumentEnum.ORDERS){
            return 'הזמנות'
        } else if(documentType == DocumentEnum.DELIVERY){
            return 'תעודות משלוח'
        } else if(documentType == DocumentEnum.INVOICES){
            return 'קבלות'
        } else if(documentType == DocumentEnum.INVOICES_MAS){
            return 'חשבוניות מס'
        } else if(documentType == DocumentEnum.INVOICES_DEAL){
            return 'חשבוניות עסקה'
        }
    }
    const formatDisplayDate = (date: Date | null) =>
        date ? moment(date).format('YYYY-MM-DD') : '';

    return (
        <Box sx={{ padding: '0 10px' }}>
            <Box sx={{display:'flex', gap:'5px'}}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Component 2">
                    <path
                    id="Icon"
                    d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z"
                    stroke="#2F4B6C"
                    style={{ stroke: "#2F4B6C", strokeOpacity: 1 }}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                </g>
                </svg>
                <Typography variant='h5' color='#154D72' fontSize={'20px'} lineHeight={'28px'} fontWeight={700}>
                    {handleTitle()}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent:'space-between', bgcolor:'white', borderRadius:'14px', padding:'12px 12px 12px 10px', border:'1px solid #80A8B6', mt:'20px' }}>
                <Box sx={{display:'flex', gap:'5px'}}>
                    <Typography onClick={() => setOpenPicker('from')} color='#546D79' fontSize={'13px'} lineHeight={'19px'} fontWeight={400}>
                        {formatDisplayDate(dateFrom)}
                    </Typography>
                    <Typography color='#546D79' fontSize={'13px'} lineHeight={'19px'} fontWeight={400}>
                        -
                    </Typography>
                    <Typography onClick={() => setOpenPicker('to')} color='#546D79' fontSize={'13px'} lineHeight={'19px'} fontWeight={400}>
                        {formatDisplayDate(dateTo)}
                    </Typography>
                </Box>
                <MyIcon/>
            </Box>
            {openPicker && (
                <DatePicker
                    sx={{display:'none'}}
                    open={!!openPicker}
                    onClose={() => setOpenPicker(null)}
                    value={moment(openPicker === 'from' ? dateFrom : dateTo)}
                    onChange={(e) => handleDateChange(e)}
                />
            )}
        </Box>
    );
};

export default Filter;
