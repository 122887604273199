import { Box, Button, Card, CardMedia, IconButton, Skeleton, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useCart } from '../../store/cart.store';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useProduct } from '../../store/product.store';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCatalog } from '../../store/catalog.store';
import { themeColors } from '../../styles';

interface CatalogCard {
    item: IProduct;
}

const CatalogCard: FC<CatalogCard> = ({ item }) => {
    const { getCartItem, addToCart, increaseCart, decreaseCart } = useCart();
    const { setFavorites, isExistFavorite } = useCatalog();
    const cart = getCartItem(item);
    const navigate = useNavigate();
    const { setSelectedProduct } = useProduct();
    const [loading, setLoading] = useState(true)
    const handleClick = () => {
        setSelectedProduct(item);
        navigate(`${URLS.PRODUCT_PAGE.LINK}/${item.sku}`);
    };

    const handleFavoriteToggle = () => {
        setFavorites(item);
    };

    const handleImageLoad = () => {
        setLoading(false)
    }

    return (
        <Card sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            padding: '10px 15px', 
            borderRadius: '15px', 
            position:'relative', 
            mt:'8px',
            border:cart ? `1px solid ${themeColors.primary}` : null
        }}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                    <IconButton onClick={handleFavoriteToggle} sx={{position:'absolute'}}>
                        {isExistFavorite(item.id) ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon sx={{color:'white'}}/>}
                    </IconButton>
                    {loading && (
                        <Skeleton
                            variant="rectangular"
                            sx={{
                                borderRadius: '15px',
                                width: '100px',
                                height: '100px',
                                flexShrink: 0 
                            }}
                        />
                    )}
                <CardMedia
                    component="img"
                    image={item?.image ? `${process.env.REACT_APP_MEDIA_BACKEND}/${item.image.filePath}` : `${process.env.REACT_APP_MEDIA}/placeholder.png`}
                    alt="Product image"
                    sx={{ 
                        width: '100px', 
                        height: '100px', 
                        borderRadius: '15px',
                        display: loading ? 'none' : 'block',
                        cursor: 'pointer',
                    }}
                    onClick={handleClick}
                    onLoad={handleImageLoad}
                />

                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={'21px'} color={themeColors.title}>{item?.title}</Typography>
                    <Typography fontSize={'13px'} lineHeight={'19.5px'} fontWeight={400} color='#436F7C'>
                    {item?.unitQuantity} {item?.unitname} · {item?.inPack} {item?.packUnit} {item?.packName}
                    </Typography>
                    {cart &&
                        <Typography fontSize={'15px'} fontWeight={700} lineHeight={'22.5px'} color='primary' sx={{mb:'5px'}}>
                            {item?.packQuantity * cart.quantity} {item?.packUnit} סה״כ בהזמנה
                        </Typography>
                    }
                </Box>

          

            </Box>
            <Box sx={{ml:'10px'}}>
                {!cart ? (
                    <Button 
                        variant="contained" 
                        sx={{
                            width: '35px',
                            height: '35px',
                            minWidth: '35px', 
                            padding: '10px',
                            borderRadius:'10px',
                            boxShadow: '0px 3px 10px 0px rgba(0, 125, 131, 0.30), 0px 3px 10px 0px color(display-p3 0.0928 0.4813 0.5072 / 0.30)'  
                        }} 
                        onClick={() => addToCart(item)}
                    >
                        <AddIcon />
                    </Button>
                ) : (
                    <Box sx={{ bgcolor: '#F5F7FA', borderRadius: '15px' }}>
                        <IconButton onClick={() => increaseCart(item?.sku)}>
                            <AddIcon color="primary" sx={{}} />
                        </IconButton>
                        <Typography textAlign={'center'}>{cart?.quantity}</Typography>
                        <IconButton onClick={() => decreaseCart(item?.sku)}>
                            <RemoveIcon color="primary" />
                        </IconButton>
                    </Box>
                )}
            </Box>
            
        </Card>
    );
};

export default CatalogCard;
