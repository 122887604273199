// Global
import React, { FC, createContext, useContext, ReactNode, useEffect, useState } from 'react';
import backgroundGif from '../styles/bg.gif';
import backgroundGifDark from '../styles/splashDark.gif';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Typography } from '@mui/material';
import Logo1 from '../styles/logoSplash.svg';
import Logo2 from '../styles/logo2Splash.svg';
import Logo1Dark from '../styles/LogoDark.svg';
import Logo2Dark from '../styles/Logo2Dark.svg';

// Defines
interface SplashContextType {}

const SplashContext = createContext<SplashContextType | null>(null);

// React hook
const useSplash = () => {
    const context = useContext(SplashContext);
    if (!context) {
        throw new Error('Can not run without "SplashContext Provider"');
    }
    return context;
};

interface SplashsProviderProps {
    children: ReactNode;
    duration?: number; 
}

const SplashsProvider: FC<SplashsProviderProps> = ({ children, duration = 5000 }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    const value = {};

    return (
        <SplashContext.Provider value={value}>
            {loading && <SplashScreen duration={duration} /> }
            {children}
        </SplashContext.Provider>
    );
};

interface SplashScreenProps {
    duration: number; 
}

const SplashScreen: FC<SplashScreenProps> = ({ duration }) => {
    const [progress, setProgress] = useState(0);
    const [theme, setTheme] = useState(
        window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
      );

    useEffect(() => {
        const timerDuration = duration; 
        const intervalTime = 50; 
        const totalSteps = timerDuration / intervalTime; 
        const increment = 100 / totalSteps;

        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev + increment >= 100) {
                    clearInterval(interval); 
                    return 100; 
                }
                return prev + increment;
            });
        }, intervalTime);

        return () => clearInterval(interval); 
    }, [duration]);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${ theme === 'dark' ? backgroundGifDark : backgroundGif })`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999, 
            }}
        >
            {theme === 'dark' ?
                <Box sx={{justifyContent:'center', display:'flex', alignItems:'center'}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml:'15px' }}>
                        <img src={Logo1Dark} alt="Logo 1" style={{ maxWidth: '80%', marginBottom: '20px' }} />
                        <img src={Logo2Dark} alt="Logo 2" style={{ maxWidth: '80%' }} />
                        <Typography fontSize={'15px'} fontWeight={500} color='white' sx={{mt:'10px'}}>
                            פוטרל לקוחות
                        </Typography>
                    </Box>
                </Box>
            :
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml:'50px'  }}>
                    <img src={Logo1} alt="Logo 1" style={{ maxWidth: '80%', marginBottom: '20px' }} />
                    <img src={Logo2} alt="Logo 2" style={{ maxWidth: '80%' }} />
                    <Typography fontSize={'15px'} fontWeight={500} color='#436F7C' sx={{mt:'10px'}}>
                        פוטרל לקוחות
                    </Typography>
                </Box>
            }

            <LinearProgress 
                variant="determinate" 
                value={progress} 
                sx={{ 
                    width: '50%', 
                    height: '8px',
                    borderRadius:'358px',
                    position: 'absolute', 
                    bottom: '200px',
                    bgcolor: 'gray', 
                    '& .MuiLinearProgress-bar': {
                        bgcolor: 'white', 
                    },
                }} 
            />
        </Box>
    );
};

export { useSplash, SplashsProvider };
