import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import Header from './Header';
import FooterNavigation from './FooterNavigation';
import PullToRefresh from 'react-simple-pull-to-refresh';
import theme, { themeColors } from '../../styles';
import { useLocation } from 'react-router-dom';
import URLS from '../../enums/route';
import useDataMachines from '../../hooks/useDataMahines';
import useDataLastOrders from '../../hooks/useDataLastOrders';
import useDataLastServices from '../../hooks/useDataLastServices';
import useDataCatalog from '../../hooks/useDataCatalog';
import useDataNotifications from '../../hooks/useDataNotifications';
import CustomCircularProgress from '../Common/Preloader';

interface LayoutProps {
    children: React.ReactNode;
    widhoutPadding?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, widhoutPadding = true }) => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { pathname } = useLocation();
    const { data } = useDataMachines();
    const { mutate: lastOrdersMutate, isLoading: isLoadingOrders } = useDataLastOrders(); 
    const { mutate: lastServicesMutate, isLoading: isLoadingServices } = useDataLastServices();
    const { mutate: catalogMutate, isLoading: isLoadingCatalog } = useDataCatalog()
    const { mutate: machineMutate, isLoading: machineLoading } = useDataMachines();
    const { mutate: notificationMutate, isLoading: notificationLoading } = useDataNotifications()
    const waitForLoadingToFinish = (loadingState: () => boolean) => {
        return new Promise<void>((resolve) => {
            const checkLoading = () => {
                if (!loadingState()) {
                    resolve();
                } else {
                    setTimeout(checkLoading, 50); 
                }
            };
            checkLoading();
        });
    };
    
    
    const waitForLoadingToStart = (loadingState: () => boolean, timeout = 2000) => {
        return new Promise<void>((resolve, reject) => {
            const startTime = Date.now();
            const checkLoading = () => {
                if (loadingState()) {
                    resolve();
                } else if (Date.now() - startTime > timeout) {
                    reject(new Error('Timeout waiting for loading to start'));
                } else {
                    setTimeout(checkLoading, 50);
                }
            };
            checkLoading();
        });
    };
    
    const handleRefresh = async () => {
        setIsRefreshing(true);
        try {
            if (pathname === URLS.HOME.LINK) {
                await lastOrdersMutate(undefined);
                await waitForLoadingToStart(() => isLoadingOrders).catch((error) => {
                    console.error('Timeout waiting for isLoadingOrders to start:', error.message);
                });
                await waitForLoadingToFinish(() => isLoadingOrders);
                await lastServicesMutate(undefined);
                await waitForLoadingToStart(() => isLoadingServices).catch((error) => {
                    console.error('Timeout waiting for isLoadingServices to start:', error.message);
                });
    
                await waitForLoadingToFinish(() => isLoadingServices);
            }
    
            if (pathname === URLS.CATALOG.LINK) {
                await catalogMutate(undefined);
                await waitForLoadingToStart(() => isLoadingCatalog).catch((error) => {
                    console.error('Timeout waiting for isLoadingCatalog to start:', error.message);
                });
                await waitForLoadingToFinish(() => isLoadingCatalog);
            }

            if(pathname === URLS.SERVICE.LINK){
                await machineMutate(undefined);
                await waitForLoadingToStart(() => machineLoading).catch((error) => {
                    console.error('Timeout waiting for isLoadingCatalog to start:', error.message);
                });
                await waitForLoadingToFinish(() => machineLoading);
            }

            if(pathname === URLS.NOTIFICATIONS.LINK){
                await notificationMutate(undefined);
                await waitForLoadingToStart(() => notificationLoading).catch((error) => {
                    console.error('Timeout waiting for isLoadingCatalog to start:', error.message);
                });
                await waitForLoadingToFinish(() => notificationLoading);
            }
    
            console.log('Refresh process completed');
        } catch (error) {
            console.error('Error during refresh:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Box
                component="header"
                sx={{
                    position: 'fixed',
                    top: 0,
                    paddingTop: '50px',
                    left: 0,
                    right: 0,
                    zIndex: 1100,
                    bgcolor: themeColors.baseDark,
                }}
            >
                <Header />
                {isRefreshing && (
                    <>
                    {pathname === URLS.HOME.LINK &&
                        <Box sx={{
                                boxShadow: '0px 7px 7px 0px rgba(0, 0, 0, 0.10)', 
                                height:'8px',
                        }}/>
                    }
               
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: '10px',
                       
                    }}
                    >
                        <CustomCircularProgress />
                    </Box>
                    </>
        
                )}
            </Box>
            <PullToRefresh
                onRefresh={handleRefresh}
                refreshingContent={<></>}
            >
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        marginTop: '165px',
                        marginBottom: '104px',
                        overflowY: 'auto',
                        position: 'relative',
                        padding: widhoutPadding ? '0 10px' : '0px',
                        paddingBottom: '40px',
                    }}
                >
                    {children}
                </Box>
            </PullToRefresh>
            <Box
                component="footer"
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1100,
                }}
            >
                {pathname === URLS.SERVICE.LINK && data?.length === 0 && (
                    <Box>
                        <Typography color="#79A3B4" fontSize={'13px'} fontWeight={700} textAlign={'center'}>
                            אופס!
                        </Typography>
                        <Typography sx={{ mb: '16px' }} color="#79A3B4" fontSize={'13px'} fontWeight={400} textAlign={'center'}>
                            נראה שאין פה כלום כרגע.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={`${process.env.REACT_APP_MEDIA}/noMachines.svg`} style={{ alignItems: 'center' }} />
                        </Box>
                    </Box>
                )}
                <FooterNavigation />
            </Box>
        </Box>
    );
};

export default Layout;
