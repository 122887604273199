import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import useDataMachineDocument from '../../hooks/useDataMachineDocument';
import { ServiceStatus } from '../../enums/serviceStatus';
import ModalWrapper from '../Modals/ModalWrapper';
import { ServicesService } from '../../services/services.service';
import { useParams } from 'react-router-dom';
import { useNotify } from '../../provider/NotifyProvider';
import { useAuth } from '../../store/auth.store';

const MySVGIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1407_20565)">
      <path
        d="M15.6314 8.13137C15.2353 7.73535 15.0373 7.53735 14.9631 7.30902C14.8979 7.10817 14.8979 6.89183 14.9631 6.69098C15.0373 6.46265 15.2353 6.26465 15.6314 5.86863L18.4697 3.03026C17.7165 2.68962 16.8804 2.5 16 2.5C12.6863 2.5 9.99998 5.18629 9.99998 8.5C9.99998 8.99104 10.059 9.4683 10.1702 9.92509C10.2894 10.4142 10.349 10.6588 10.3384 10.8133C10.3273 10.9751 10.3032 11.0612 10.2286 11.2051C10.1574 11.3426 10.0208 11.4791 9.7478 11.7522L3.49998 18C2.67156 18.8284 2.67156 20.1716 3.49998 21C4.32841 21.8284 5.67156 21.8284 6.49998 21L12.7478 14.7522C13.0208 14.4791 13.1574 14.3426 13.2949 14.2714C13.4388 14.1968 13.5249 14.1727 13.6867 14.1616C13.8412 14.151 14.0857 14.2106 14.5749 14.3297C15.0317 14.441 15.5089 14.5 16 14.5C19.3137 14.5 22 11.8137 22 8.5C22 7.61959 21.8104 6.78347 21.4697 6.03026L18.6314 8.86863C18.2353 9.26465 18.0373 9.46265 17.809 9.53684C17.6082 9.6021 17.3918 9.6021 17.191 9.53684C16.9626 9.46265 16.7646 9.26465 16.3686 8.86863L15.6314 8.13137Z"
        stroke="#FF32BB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 22.75L1.75 4.75"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
      />
      <path
        d="M20.2485 22.749L1.74847 4.74902"
        stroke="#FF32BB"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1407_20565">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

const IconSVG = () => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        d="M6.99997 14.3255C6.99997 13.8363 6.99997 13.5917 7.05523 13.3615C7.10423 13.1574 7.18504 12.9624 7.29469 12.7834C7.41838 12.5816 7.59133 12.4086 7.93723 12.0627L17.5 2.49998C18.3284 1.67155 19.6716 1.67156 20.5 2.49998C21.3284 3.32841 21.3284 4.67156 20.5 5.49998L10.9373 15.0627C10.5914 15.4086 10.4184 15.5816 10.2166 15.7053C10.0376 15.8149 9.84254 15.8957 9.63846 15.9447C9.40829 16 9.1637 16 8.67452 16H6.99997V14.3255Z"
        fill="#27C9D4"
      />
      <path
        d="M10 3.99998H5.8C4.11984 3.99998 3.27976 3.99998 2.63803 4.32696C2.07354 4.61458 1.6146 5.07353 1.32698 5.63801C1 6.27975 1 7.11983 1 8.79998V17.2C1 18.8801 1 19.7202 1.32698 20.362C1.6146 20.9264 2.07354 21.3854 2.63803 21.673C3.27976 22 4.11984 22 5.8 22H14.2C15.8802 22 16.7202 22 17.362 21.673C17.9265 21.3854 18.3854 20.9264 18.673 20.362C19 19.7202 19 18.8801 19 17.2V13M6.99997 16H8.67452C9.1637 16 9.40829 16 9.63846 15.9447C9.84254 15.8957 10.0376 15.8149 10.2166 15.7053C10.4184 15.5816 10.5914 15.4086 10.9373 15.0627L20.5 5.49998C21.3284 4.67156 21.3284 3.32841 20.5 2.49998C19.6716 1.67156 18.3284 1.67155 17.5 2.49998L7.93723 12.0627C7.59133 12.4086 7.41838 12.5816 7.29469 12.7834C7.18504 12.9624 7.10423 13.1574 7.05523 13.3615C6.99997 13.5917 6.99997 13.8363 6.99997 14.3255V16Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);


const Edit = () => {
    const [loading, setLoading] = useState(false)
    const { onErrorNotify, onSuccessNotify} = useNotify()
    const {data} = useDataMachineDocument()
    const { user } = useAuth()
    const {id} = useParams()
    const [text,setText] = useState('')
    const isCanEdit = (data?.status === ServiceStatus.Online || data?.status === ServiceStatus.Open || data?.status === ServiceStatus.ForAssignment) 
    const [openDelete, setOpenDelete] = useState(false)
    
    const handleDelete = () => {
      if(!user?.permission?.permissionDetaileds[0]?.isServiceEditable){
        onErrorNotify('אין הרשאה לערוך קריאת שירות או לבטל קריאת שירות') 
        return 
      }
      try {
        setLoading(true)
        const response = ServicesService.updateService(id!,true,`${data?.comment} - ${text}`).then((e) => {
          e.status === 'success' ? onSuccessNotify(e.message) : onErrorNotify(e.message) 
        })
        
      } catch(e) {
        console.log('[ERROR]:',e)
      } finally {
        setLoading(false)
        setOpenDelete(false)
      }
    }

    return (
        <Box sx={{gap:'10px', display:'flex'}}>
          {isCanEdit &&
            <>
              <Button 
                variant='outlined' 
                color='info' 
                fullWidth 
                onClick={() => setOpenDelete(true)}
                endIcon={
                  <MySVGIcon/>
                }
                >
                  ביטול קריאה
                </Button>  
              {/* <Button 
                variant='contained' 
                color='primary' 
                fullWidth
                endIcon={
                  <IconSVG/>
                }
              >
                עריכת קריאה
              </Button>   */}
            </>
          }
          <ModalWrapper 
            active={openDelete} 
            setActive={setOpenDelete} 
            component={
              <Typography color='#154D72' fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
              לבטל קריאת שירות?
              </Typography>
            }
          >
            <Box>

              <Typography fontSize={'13px'} lineHeight={'19.5px'} fontWeight={700} color='#436F7C'> 
              שימו לב! 
              </Typography>
              <Typography fontSize={'13px'} lineHeight={'19.5px'} fontWeight={400} color='#436F7C'>
              אתם עומדים לבטל את קריאת השירות, בלחיצה על ״בטל קריאה״ המידע שהוזן בקריאה הזו לא ישמר.
              </Typography>
              <Typography fontSize={'15px'} lineHeight={'22.5px'} fontWeight={700} color='#436F7C' sx={{mt:'30px'}}>
              ציינו את סיבת ביטול הקריאה
              </Typography>
              <Typography fontSize={'13px'} lineHeight={'19.5px'} fontWeight={400} color='#436F7C'>
              הזינו לפחות 15 תווים
              </Typography>
              <TextField 
                sx={{mt:'8px'}}
                placeholder='סיבת ביטול קריאת השירות'
                fullWidth
                multiline
                rows={2}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <Box>
                <Button 
                sx={{mt:'20px'}}
                color='info'
                variant='outlined' 
                fullWidth
                disabled={text.length < 10}
                onClick={handleDelete}
                endIcon={
                  <MySVGIcon/>
                }
                >
                ביטול קריאת שירות
                </Button>
              </Box>
              <Box>
                <Button 
                sx={{
                  mt:'20px'
                }}
                fullWidth
                onClick={() => setOpenDelete(false)}
                >
                חזרה לסיכום הקריאה
                </Button>
              </Box>
            </Box>
          </ModalWrapper>
        </Box>
    );
};

export default Edit;