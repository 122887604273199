import AuthHeader from "./AuthHeader";
import LoginForm from "./LoginForm";
import ResertPasswordStepOneForm from "./ResertPasswordStepOneForm";
import ResetPasswordStepTwoForm from "./ResetPasswordStepTwoForm";
import ResetPasswordStepThreeForm from "./ResetPasswordStepThreeForm";
import AuthStepOneForm from "./AuthStepOneForm";
import AuthStepTwoForm from "./AuthStepTwoForm";
import AuthStepThreeForm from "./AuthStepThreeForm";

export default {
    AuthHeader,
    LoginForm,
    ResertPasswordStepOneForm,
    ResetPasswordStepTwoForm,
    ResetPasswordStepThreeForm,
    AuthStepOneForm,
    AuthStepTwoForm,
    AuthStepThreeForm,
}