import useSWR from 'swr';
import { DocumentService } from '../services/document.service';

const fetchData = async (
  documentNumber: string,
): Promise<IDocument> => {
  return await DocumentService.getDocumentItems(documentNumber);
};

const useDataDocumentItems = (documentNumber?: string) => {
  const { data, error, isLoading, mutate } = useSWR<IDocument>(
    documentNumber ? `/client/documents/orders/${documentNumber}` : null, 
    () => fetchData(documentNumber!)
  );

  return {
    data,
    isLoading: isLoading ?? false, 
    isError: !!error,
    mutate,
  };
};

export default useDataDocumentItems;
