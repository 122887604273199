import { useState } from 'react';
import { QuestionEnum } from '../types/questionEnum';
import useDataQuestion from './useDataQuestion';
import { QuestionService } from '../services/question.service';
import { useNotify } from '../provider/NotifyProvider';
import { useAuth } from '../store/auth.store';
import { useParams } from 'react-router-dom';
import useDataDocumentItems from './useDataDocumentItems';
import useDataMachineDocument from './useDataMachineDocument';

export interface IQuestion {
    id: number;
    title: string;
    stars: number;
    type: string;
    extId: string;
}

const useIllustration = (questionType: QuestionEnum) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState<number | null>(null);
    const [selectedChips, setSelectedChips] = useState<IQuestion[]>([]);
    const [text, setText] = useState('');
    const { mutate: orderMutate } = useDataDocumentItems(id!)
    const { mutate: serviceMutate } = useDataMachineDocument()
    const { data: rawData } = useDataQuestion(questionType);
    const { onSuccessNotify, onErrorNotify } = useNotify();
    const { user } = useAuth();

    const data = rawData?.map((item) => ({
        ...item,
        type: item.type || 'default',
        extId: item.extId || 'unknown',
    })) as IQuestion[];

    const filterTags = () => {
        if (value) {
            return data?.filter((item) => item.stars >= value) || [];
        }
        return [];
    };

    const handleChipClick = (chip: IQuestion) => {
        setSelectedChips((prevSelected) =>
            prevSelected.some((item) => item.id === chip.id)
                ? prevSelected.filter((item) => item.id !== chip.id)
                : [...prevSelected, chip]
        );
    };

    const handleDelete = (chip: IQuestion) => {
        setSelectedChips((prevSelected) =>
            prevSelected.filter((item) => item.id !== chip.id)
        );
    };
    const handleSend = async () => {
        setLoading(true)
        try {
            QuestionService.createQuestion(
                value ?? 0,
                selectedChips,
                text,
                user!,
                questionType,
                id!
            ).then(async(e) => {
                if (e.status === 'success') {
                    onSuccessNotify(e.message);
                } else {
                    onErrorNotify(e.message);
                }
                if(questionType === QuestionEnum.ORDER){
                    await orderMutate()
                } 
                if(questionType === QuestionEnum.SERVICE){
                    await serviceMutate()
                }
                setLoading(false)
            });
        } catch (e) {
            console.error('[ERROR]', e);
        } 
    };

    return {
        loading,
        value,
        setValue,
        selectedChips,
        setSelectedChips,
        text,
        setText,
        data,
        filterTags,
        handleChipClick,
        handleDelete,
        handleSend,
    };
};

export default useIllustration;
